/*!
Template Name: Gamerzy
Author: PixianStudio
Description: Description
Version: 1.0.0
Text Domain: Gamerzy
Tags: gaming, sport, esports, gaming website, gaming template, html5, bootstrap ,game,Saas, app landing, event.

*/
/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# 1. Configuration and helpers css
# 2. Vendors css
# 3. Base stuff css
# 4. Layout-related sections css
# 5. Components css
# 6. Page-specific styles css
# 7. Themes css
--------------------------------------------------------------*/





@charset "UTF-8";

// 1. Configuration and helpers
@import 'abstracts/variables',
'abstracts/mixins';

// 2. Vendors
@import 'vendors/normalize',
'vendors/rfs';

// 3. Base stuff
@import 'base/fonts',
'base/typography',
'base/animation',
'base/extend',
'base/utilities';

// 4. Layout-related sections
@import 'layout/global',
'layout/header',
'layout/banner',
'layout/footer';

// 5. Components
@import 'components/button',
'components/sponsor',
'components/tournament',
'components/game',
'components/team',
'components/standing',
'components/game-item',
'components/player-item',
'components/match-item',
'components/video-item',
'components/product-item',
'components/testmonial-item',
'components/gallery-item',
'components/blog-item',
'components/partner-item',
'components/coming-soon',
'components/contact-item';

// 6. Page-specific styles
@import 'pages/home';
@import 'pages/shop';
@import 'pages/shop-single';
@import 'pages/cart-page';
@import 'pages/achievement';
@import 'pages/accounts';
@import 'pages/fore-zere';

// 7. Themes
@import 'themes/default';